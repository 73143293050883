import * as React from 'react'
import Layout from '../components/layout'

const IndexPage = () => {
  return (<Layout pageTitle="Home">
    <p>
      Find me on <a target="_blank" rel="noreferrer" href="https://linkedin.com/in/jgcoded">LinkedIn</a> and <a target="_blank" rel="noreferrer" href="https://github.com/jgcoded">Github</a>.
    </p>
  </Layout>
  )
}

export default IndexPage